import store from "../store";
import {Brands} from "@/brand/brandConfig";
import DcbService from "../services/dcbService";
import UpstreamIntegrationService from "../services/upstreamIntegrationService";
import UtilsService from "../services/utilsService";
import getCampaignParametersFromUrl from "./getCampaignParametersFromUrl";
import {handleActionClick} from "./handleActionClick";
import {triggerPostbackForSuccessfulLogin} from "./utagDataPost";
import {handleDcbGameRedirects} from "@/functions/handleDcbGameRedirects";

export async function userAuthenticate(to, from, next) {
    const {brand} = store.state.brandConfig;
    const {productId} = store.state.brandConfig;

    switch (brand) {
        case Brands.VodacomUnlockThree:
        case Brands.VodacomStopClock:
        case Brands.VodacomHeadToHead:
        case Brands.VodacomBeatTheJams:
        case Brands.VodacomQuizInc:
        case Brands.VodacomMasterStream:
        case Brands.VodacomMzansiGames:
            return await userAuthenticateInternalEvina(to, from, next, productId);
        case Brands.VodacomEvinasUnlockThree:
        case Brands.VodacomEvinaBeatTheJams:
        case Brands.VodacomEvinaHeadToHead:
        case Brands.VodacomEvinaQuizInc:
        case Brands.VodacomEvinaStopClock:
        case Brands.VodacomEvinaMasterStream:
            return await userAuthVodacomEvina(to, from, next, productId);
        case Brands.VodacomFantasyFootball:
            return await userAuthenticateFantasyFootball(to, from, next);
        default:
            return await userAuthenticateInternalEvina(to, from, next, productId);
    }
}

async function userAuthenticateInternalEvina(to, from, next, productId) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    const eMsisdn = urlParams.get('e-msisdn');
    getCampaignParametersFromUrl(urlParams);

    if (token || eMsisdn) {
        if (token) return await utilsEncryptedMsisdnAuth(token, next, productId);
        if (eMsisdn) return await dcbEncryptedMsisdnAuth(eMsisdn, next, productId);
    } else if (to.name === 'OnWifiInternalEvina' || to.name === 'TimeoutError' || to.name === 'GenericError') {
        return next();
    }
    //TODO Review this as well
    return next();
}

async function userAuthenticateFantasyFootball(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token') ?? localStorage.getItem('token');
    getCampaignParametersFromUrl(urlParams);

    if (token) {
        if (token) return await upstreamEncryptedMsisdnAuth(token, next);
        //TODO the insufficient funds here will cause problems sending the user on a different journey potentially.
    } else if (to.name === 'OnWifiUpstream' || to.name === 'TimeoutError' || to.name === 'GenericError' || to.name === "InvalidUser" || to.name === "InsufficientFunds" || to.name === "ContentBlock") {
        return next();
    }
    return next();
}

async function userAuthVodacomEvina(to, from, next) {
    const urlParams = new URLSearchParams(window.location.search);
    getCampaignParametersFromUrl(urlParams);

    if (to.name === 'OnWifiInternalEvina' || to.name === 'DoiTwoVodacomEvina' || to.name === 'GenericEvinaError' || to.name === 'TimeoutError' || to.name === 'OnWifiVodacomEvina' || to.name === "EvinaInsufficientFunds" || to.name === "ContentBlock" || to.name === "ContentBlockOverride" || to.name === "InvalidUser") {
        return next();
    }

    const encryptedMsisdn = urlParams.get('encryptedMsisdn') ?? localStorage.getItem('encryptedMsisdn');
    if (encryptedMsisdn) {
        return await storeUserDetails(encryptedMsisdn, next);
    } else {
        return next({name: 'GenericEvinaError'});
    }
}

async function storeUserDetails(encryptedMsisdn, next) {
    const {
        pricepoint,
        subscription
    } = await DcbService.getServiceOffers(encryptedMsisdn, store.state.brandConfig.serviceId);
    return redirectBasedOnEncryptedUserStatus(encryptedMsisdn, pricepoint, subscription, next);
}

async function redirectBasedOnEncryptedUserStatus(encryptedMsisdn, pricepoint, subscription, next) {
    const user = await DcbService.getEncryptedUserViaSubscriptionId(subscription.subscriptionId, store.state.brandConfig.serviceId);
    if (subscription) {
        this.triggerPostbackForSuccessfulLogin();
        return handleDcbGameRedirects(user.msisdn);
    } else if (user.status === 'Content Blocked') return next({name: 'ContentBlock'});
    else return next({name: 'GenericEvinaError'});
}

function setMsisdn(msisdn) {
    store.commit('setMsisdn', msisdn);
}

function setUser(user) {
    store.commit('setUser', user);
}

async function utilsEncryptedMsisdnAuth(token, next, productId) {
    const msisdn = await UtilsService.getMsisdnFromEncryptedMsisdn(token);
    const user = await DcbService.getUserViaProductId(msisdn, productId);
    setUser(user);
    return await redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, msisdn);
}

async function dcbEncryptedMsisdnAuth(eMsisdn, next, productId) {
    const msisdn = await DcbService.decryptMsisdn(eMsisdn);
    setMsisdn(msisdn);
    const user = await DcbService.getUserViaProductId(msisdn, productId);
    setUser(user);
    return await redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, msisdn);
}

async function upstreamEncryptedMsisdnAuth(token, next) {
    const msisdn = await UtilsService.getMsisdnFromEncryptedMsisdn(token);
    const fantasyToken = (await UpstreamIntegrationService.msisdnLogin(msisdn)).data.token;
    const user = (await UpstreamIntegrationService.getUserStatus(fantasyToken)).data
    return await redirectUpstreamUserBasedOnUserStatusMarketingFlow(user, next);
}

async function redirectDcbUserBasedOnUserStatusMarketingFlow(user, next, msisdn) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (action) return await handleActionClick(user, next, msisdn);
    switch (user.status) {
        case "Active":
            if (!store.state.brandConfig.multiTierService || user.serviceId === store.state.brandConfig.serviceId) return await encryptedDcbMsisdnLogin(user);
            break;
        case "Content Blocked":
            return next({path: '/generic-error'});
        case "Interested":
            return next();
        case "Unsubscribed":
            return next();
        default:
            return next();
    }
}

async function redirectUpstreamUserBasedOnUserStatusMarketingFlow(user, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get('action');

    if (user.status === "Subscribed") {
        return await tokenUpstreamLogin(user.token);
    }

    if (user.status === 'Content Blocked') {
        return next({path: '/generic-error'});
    }

    if (action) {
        return await handleActionClick(user, next);
    } else {
        window.location = `${store.state.brandConfig.gameUrl}`;
    }
}

async function encryptedDcbMsisdnLogin(user) {
    const encryptedMsisdn = (await DcbService.getEncryptedMsisdn(user.msisdn)).data.encryptedMsisdn;
    triggerPostbackForSuccessfulLogin();
    return handleDcbGameRedirects(encryptedMsisdn);
}

async function tokenUpstreamLogin(token) {
    window.location = `https://fantasysoccer.vodacomfantasy.com/?fantasyToken=${token}`
}
